import React, { ComponentType } from 'react';
interface Props {
  image: string;
  title: string;
  subTitle: string;
}
export const USPContainer: ComponentType<Props> = props => {
  const { image, title, subTitle, children } = props;
  return (
    <div className="container is-max-desktop">
      <div className="columns">
        <div className="column">
          <div className="icon-holder is-flex is-align-items-center is-justify-content-center">
            <img src={`images/icon/icon-${image}.png`} />
          </div>
        </div>
        <div className="column">
          <h2 className="title">{title}</h2>
          <h3 className="subtitle">{subTitle}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};
