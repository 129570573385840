import React, { ComponentType } from 'react';
import { Gradient } from 'react-gradient';
import { Carousel } from 'react-responsive-carousel';
interface Props {
  testimonials: {
    image: string;
    name: string;
    quote: string;
    production: string;
    background: string;
    color: string;
  }[];
}
export const TestimonialSlider: ComponentType<Props> = props => {
  const { testimonials } = props;
  return (
    <Carousel
      centerMode={false}
      className="testimonials"
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      showIndicators={false}
      swipeable
      emulateTouch
      infiniteLoop={true}
      interval={3000}
      swipeScrollTolerance={10}
      autoPlay={true}
    >
      {testimonials.map(t => (
        <Gradient
          gradients={[
            /* ['#bd19d6', '#ea7d10'],
            ['#ff2121', '#25c668'] */
            [t.background, '#efefef'],
            ['#efefef', t.background]
          ]} // required
          property="background"
          duration={2000}
          angle="45deg"
        >
          <div className="content" style={{ color: t.color }}>
            <p className="quote" style={{ color: t.color }}>
              {t.quote}
            </p>
            <div className="author">
              <img src={`images/testimonials/${t.image}.png`} />

              <div className="name" style={{ color: t.color }}>
                {t.name}
              </div>
              <div className="production" style={{ color: t.color }}>
                {t.production}
              </div>
            </div>
          </div>
        </Gradient>
      ))}
    </Carousel>
  );
};
