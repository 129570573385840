import classNames from 'classnames';
import React, { ComponentType, useMemo } from 'react';
interface Props {
  features: {
    title: string;
    subtitle?: string;
    description: string;
    image?: string;
    screenshot?: string | string[];
  }[];
}
export const FeatureTiles: ComponentType<Props> = props => {
  const { features } = props;
  const grid = useMemo(() => {
    const rows = [];

    let i = 0;
    let row = [];
    for (const f of features) {
      i++;
      if (i === 1) {
        rows.push(row);
      }
      row.push(f);
      if (i >= 3) {
        row = [];
        i = 0;
      }
    }
    return rows;
  }, [features]);

  return (
    <div className="feature-tiles">
      {grid.map(r => (
        <div className="tile is-ancestor">
          {r.map(f => (
            <div className="tile is-parent p-4">
              <div className="tile box is-child has-text-centered">
                {f.image && <img src={`images/icon/icon-${f.image}.png`} />}
                <h3 className="title">{f.title}</h3>
                {f.subtitle && <h4 className="subtitle">{f.subtitle}</h4>}
                <p
                  className={classNames({ subtitle: !f.subtitle })}
                  dangerouslySetInnerHTML={{ __html: f.description }}
                />
                {f.screenshot && typeof f.screenshot === 'string' && (
                  <img
                    className="screenshot"
                    src={`images/screenshots/${f.screenshot}.png`}
                  />
                )}
                {f.screenshot && Array.isArray(f.screenshot) && (
                  <div className="columns">
                    {f.screenshot.map(screenshot => (
                      <div className="column">
                        <img
                          className="screenshot"
                          src={`images/screenshots/${screenshot}.png`}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
