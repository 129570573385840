import React, { ComponentType } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Logo } from './logo';

interface Props {}

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const HeaderSlider: ComponentType<Props> = () => {
  return (
    <div className="hero-slider-wrapper">
      <AutoplaySlider
        className="hero-slider"
        organicArrows={true}
        infinite={true}
        mobileTouch={true}
        play={true}
        bullets={false}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={4000}
        fillParent={true}
        customContent={
          <div className="content">
            <Logo altDescription="fakeOS" asH1 />
            <h2>Die App fürs Set</h2>
          </div>
        }
      >
        <div className="slide" data-src="images/header/header-1.jpg"></div>

        <div className="slide" data-src="images/header/header-2.jpg"></div>
      </AutoplaySlider>
    </div>
  );
};
