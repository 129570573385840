import React from 'react';
import 'react-awesome-slider/dist/styles.css';
import { Gradient } from 'react-gradient';
import { FeatureTiles } from '../components/feature-tiles';
import { HeaderSlider } from '../components/header-slider';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';
import { TestimonialSlider } from '../components/testimonial-slider';
import { USPContainer } from '../components/usp-container';

export default () => (
  <Layout>
    <HeaderSlider />

    <Navigation />

    <section className="section mt-6">
      <div className="columns is-justify-content-center">
        <div className="column is-half has-text-centered">
          <h2 className="title is-size-2">Die App fürs Set</h2>
          <p className="subtitle is-size-4 mt-2">
            Vom Oberflächendesign über verschiedenste Funktionen bis hin zu
            maßgeschneiderten Animationen – die fakeOS Simulations-App ist das
            ultimative Allround-Tool für alle Handy-Filmszenen.
          </p>
        </div>
      </div>
    </section>

    <section className="section my-6">
      <USPContainer
        image={'alround'}
        title="Vielseitig."
        subTitle="fakeOS bietet neben allen typischen Handyfunktionen auch viele,
        speziell auf die Arbeit am Set ausgerichtete Features."
      ></USPContainer>
    </section>

    <section className="section my-6">
      <USPContainer
        image={'flexible'}
        title="Flexibel."
        subTitle="Mit fakeOS lassen sich Ereignisse beliebiger Länge und Umfangs individuell steuern. Natürlich sind Abfolge, Inhalt und Timing dabei jederzeit variabel anpassbar."
      ></USPContainer>
    </section>

    <section className="section my-6">
      <USPContainer
        image={'extendable'}
        title="Beliebig erweiterbar."
        subTitle="fakeOS wird ständig weiterentwickelt und ist stets State of the Art. Mit unseren Custom Animations setzen wir auch exklusive Feature Requests um."
      ></USPContainer>
    </section>

    <TestimonialSlider
      testimonials={[
        {
          name: 'Johannes Krimm',
          image: 'johannes',
          quote: 'Unglaublich vielseitig!',
          production: 'Bettys Diagnose',
          background: '#B4DF67',
          color: '#000000'
        },
        {
          name: 'Alina Krengel',
          image: 'alina',
          quote:
            'Man hat die Möglichkeit ganz schnell mal einen Ablauf zu ändern.',
          production: 'Marie Brand / Frau Sonntag bewährt sich',
          background: '#08C0BB',
          color: '#ffffff'
        },
        {
          name: 'Christoph Müller',
          image: 'christoph',
          quote: 'Variable und timing-genaue Anpassungen.',
          production: 'SOKO Köln / Merz gegen Merz',
          background: '#FE8212',
          color: '#ffffff'
        },
        {
          name: 'Volker von Reth',
          image: 'volker',
          quote: 'Sehr anpassungsfähig!',
          production: 'Marie Brand / Frau Sonntag bewährt sich',
          background: '#C0E601',
          color: '#000000'
        }
      ]}
    />

    <div className="section">
      <div className="container">
        <FeatureTiles
          features={[
            {
              title: 'Szenen-Editor',
              image: 'scene-editor',
              description:
                'Mit dem fakeOS Szenen-Editor kannst du beliebig viele Ereignisse (Anrufe, SMS, etc.) individuell verknüpfen und abspielen.'
            },
            {
              title: 'Timing',
              image: 'timing',
              description:
                'Inszeniere Ereignisse auf die Sekunde genau oder spiele sie per Remote ab.'
            },
            {
              title: 'Sync',
              image: 'sync',
              description:
                'Synchronisiere Szenen, Ereignisse und Kontakte mit beliebig vielen Geräten.'
            },
            {
              title: 'Personalisierung',
              image: 'personalization',
              description:
                'Personalisiere deinen Homescreen mit App-Icons und eigenen Hintergrundbildern.'
            },

            {
              title: 'Kompatibel',
              image: 'compatible',
              description:
                'Nutze deine eigenen Geräte. fakeOS ist kompatibel mit Android ab Version 5 (Lollipop).'
            },
            {
              title: 'Remote',
              image: 'remote',
              description:
                'Mit fakeOS lassen sich Ereignisse kinderleicht per Remote steuern. Eine einfache Bluetooth-Tastatur genügt.'
            }
          ]}
        />
      </div>
    </div>

    <Gradient
      className="section users-list"
      gradients={[
        ['#22C5AE', '#7CD582'],
        ['#D2E256', '#DCCC48', '#F7911E']
      ]}
      property="background"
      duration={2000}
      angle="45deg"
    >
      <div className="container has-text-centered">
        <img className="title-image" src="images/icon/icon-use-cases.png" />
        <h2 className="title is-2">fakeOS im Einsatz</h2>

        <div className="section">
          <h3 className="title">Pastewka</h3>
          <p className="subtitle">Amazon Prime, BRAINPOOL TV GmbH</p>
        </div>

        <div className="section">
          <h3 className="title">Tatort Dortmund</h3>
          <p className="subtitle">ARD, Zeitsprung Pictures GmbH</p>
        </div>

        <div className="section">
          <h3 className="title">Soko Köln</h3>
          <p className="subtitle">
            ZDF, Network Movie Film- und Fernsehproduktion GmbH)
          </p>
        </div>

        <div className="section">
          <h3 className="title">Bettys Diagnose</h3>
          <p className="subtitle">
            ZDF, Network Movie Film- und Fernsehproduktion GmbH
          </p>
        </div>

        <div className="section">
          <h3 className="title">Professor T.</h3>
          <p className="subtitle">
            ZDF, Rowboat Film- und Fernsehproduktion GmbH
          </p>
        </div>

        <div className="section">
          <h3 className="title">Merz gegen Merz</h3>
          <p className="subtitle">
            ZDF, Network Movie Film- und Fernsehproduktion GmbH
          </p>
        </div>

        <div className="section">
          <h3 className="title">Marie Brand</h3>
          <p className="subtitle">
            ZDF, Warner Bros. International Television Production Deutschland
          </p>
        </div>

        <div className="section">
          <h3 className="title">Unter Freunden stirbt man nicht</h3>
          <p className="subtitle">Vox, Tresor TV Produktions GmbH</p>
        </div>

        <div className="section">
          <h3 className="title">Die Bestatterin</h3>
          <p className="subtitle">ARD, die film GmbH</p>
        </div>

        <div className="section">
          <h3 className="title">Frau Sonntag bewährt sich</h3>
          <p className="subtitle">ARD Degeto, Gaumont GmbH</p>
        </div>

        <div className="section">
          <h3 className="title">Vous n‘aurez pas ma haine</h3>
          <p className="subtitle">Kino, Komplizen Film GmbH</p>
        </div>

        <div className="section">
          <h3 className="title">Susanne Knopf</h3>
          <p className="subtitle">SAT.1, ITV Studios Germany GmbH</p>
        </div>
      </div>
    </Gradient>

    <div className="section">
      <div className="container">
        <FeatureTiles
          features={[
            {
              title: 'Anrufe',
              image: 'call',
              screenshot: 'call',
              description:
                'Generiere ein- und ausgehende Anrufe und lege Reaktionen fest.'
            },
            {
              title: 'Telefonbücher',
              image: 'contacts',
              screenshot: 'contacts',
              description:
                'Lege Telefonbücher an, definiere Favoriten und erhalte echte Anruflisten.'
            },
            {
              title: 'Nachrichten',
              image: 'messages',
              screenshot: 'messages',
              description:
                'Erstelle komplette Nachrichtenverläufe inklusive Bild- und Sprachmessages.'
            },
            {
              title: 'Greenscreen',
              image: 'greenscreen',
              screenshot: 'greenscreen',
              description:
                'Blende Tracking-Screens oder eigene Motive zeitgenau ein.'
            },

            {
              title: 'Sperrbildschirm',
              image: 'lockscreen',
              screenshot: 'lockscreen',
              description:
                'Simuliere das Phone-Entsperren per Zahlencode oder Fingerabdruck-Sensor.'
            },
            {
              title: 'Benachrichtigungen',
              image: 'notifications',
              screenshot: 'notifications',
              description:
                'Erstelle eigene Benachrichtigungen im Sperrbildschirm.'
            },
            {
              title: 'Custom Animations',
              subtitle:
                'fakeOS sorgt für Bewegung. Maßgeschneidert und immer auf den Punkt.',
              image: 'custom',
              screenshot: ['custom-1', 'custom-2', 'custom-3', 'custom-4'],
              description:
                'Ob Dating-App, Social Network, Audioplayer oder Navigation – mit den fakeOS Custom Animations kannst du dein Set-Handy noch individueller bespielen. Einfach fakeOS synchronisieren und Animation passgenau in den Szenen-Editor einbetten. Du hast Sonderwünsche? Sprich uns an!'
            }
          ]}
        />
      </div>
    </div>
  </Layout>
);
